import React from 'react'
import amazon from '../images/stores/amazon.png'
import applemusic from '../images/stores/applemusic.png'
import bandcamp from '../images/stores/bandcamp.png'
import google from '../images/stores/google.png'
import iheart from '../images/stores/iheart.png'
import itunes from '../images/stores/itunes.png'
import napster from '../images/stores/napster.png'
import spotify from '../images/stores/spotify.png'
import reverbnation from '../images/stores/reverbnation.png'


const Stores = () => (
  <div className="stores">
    <a target="_blank" href="https://itunes.apple.com/us/album/planet-and-sky/1393063213" title="Listen on Apple Music" alt="Link to Apple Music">
      <img src={applemusic} alt="Apple Music"/>
    </a>
    <a target="_blank" href="https://itunes.apple.com/us/music/planet-and-sky/1393063213" title="Listen on iTunes" alt="Link to iTunes">
      <img src={itunes} alt="iTunes"/>
    </a>
    <a target="_blank" href="https://open.spotify.com/album/3Gx20ywNtOiluEfTTzXZaD" title="Listen on Spotify" alt="Link to Spotify">
      <img src={spotify} alt="Spotify"/>
    </a>
    <a target="_blank" href="https://maxwyvernband.bandcamp.com/releases" title="Listen on Bandcamp and currently best price for purchase" alt="Link to Bandcamp">
      <img src={bandcamp} alt="Bandcamp"/>
    </a>
    <a target="_blank" href="https://www.reverbnation.com/maxwyvernband" title="Listen on Reverb Nation" alt="Link to Tidal">
      <img src={reverbnation} alt="Reverb Nation"/>
    </a>
    <a target="_blank" href="https://www.amazon.com/gp/product/B07DGHF2F3" title="Listen on Amazon" alt="Link to Amazon">
      <img src={amazon} alt="Amazon"/>
    </a>
    <a target="_blank" href="https://play.google.com/store/music/album/Max_Wyvern_Band_Planet_and_Sky?id=B2sb7pj7iyeqk2ornuzugqnlvve" title="Listen on Google Play" alt="Link to Google Play">
      <img src={google} alt="Google Play"/>
    </a>
    <a target="_blank" href="https://www.iheart.com/artist/max-wyvern-band-32207497/albums/planet-and-sky-57811426/" title="Listen on iHeart Radio" alt="Link to iHeart Radio">
      <img src={iheart} alt="iHeart Radio"/>
    </a>
    <a target="_blank" href="https://us.napster.com/artist/max-wyvern-band/album/planet-and-sky" title="Listen on Napster" alt="Link to Napster">
      <img src={napster} alt="Napster"/>
    </a>
  </div>
)

export default Stores
