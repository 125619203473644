import React from 'react'
import Layout from '../components/layout'
import psCover from '../images/album_cover/ps-500.png'
import { Link } from 'gatsby'
import Stores from '../components/stores'

const Music = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">really quite an epic disc with brilliant musicians playing a variety of smashing tunes</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container music is-fluid">
        <div className="columns">
          <div className="column home cover has-text-centered">
            <img src={psCover} className="cover" alt="Planet and Sky album cover" />
          </div>
          <div className="column">
            <dl>
              <dt><Link to="/songs/something_is_dreaming">Something is Dreaming</Link></dt>
              <dd>Scientists arrive and investigate an enigma</dd>
              <dt><Link to="/songs/round">Round</Link></dt>
              <dd>A mythic romance begins</dd>
              <dt><Link to="/songs/travelers">Travelers</Link></dt>
              <dd>Three travelers arrive from deep space</dd>
              <dt><Link to="/songs/eons_of_joy">Eons of Joy</Link></dt>
              <dd>A young and vigorous couple create a bountiful world</dd>
              <dt><Link to="/songs/such_is_love">Such is Love</Link></dt>
              <dd>Planet ponders the depths of his love for Sky</dd>
              <dt><Link to="/songs/beautiful_life">Beautiful Life</Link></dt>
              <dd>The travelers discuss the weather</dd>
              <dt><Link to="/songs/planet_my_love">Planet My Love</Link></dt>
              <dd>Heartbreak for the cosmic couple as they are cruelly separated</dd>
              <dt><Link to="/songs/empty_world">Empty World</Link></dt>
              <dd>No voices remain on a barren and lifeless planet</dd>
              <dt><Link to="/songs/wake_up">Wake Up!</Link></dt>
              <dd>A world is re-awakenened and old friends return</dd>
            </dl>
          </div>
        </div>
        <div className="locations has-text-centered"><div className="buy-prompt is-size-4">Available now wherever you get your music:</div><Stores/></div>
      </div>
    </section>
  </Layout>
)

export default Music
